module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"D:/Projects/React/lionstigersandbears/node_modules/gatsby-remark-images","id":"3697bb45-896d-5128-8de1-acdea7fdb23e","name":"gatsby-remark-images","version":"3.3.32","pluginOptions":{"plugins":[],"maxWidth":1920,"wrapperStyle":""},"nodeAPIs":[],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".md",".mdx"],"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-images","options":{"maxWidth":1920,"wrapperStyle":""}}]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1920,"wrapperStyle":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1920,"wrapperStyle":""},
    }]
